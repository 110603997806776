import React from "react";
import "../styles/Home.css";
import HeroPage from "./Hero";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import QualityCards from "../components/qualityCards/QualityCards";
import ServicesCard from "../components/servicesCard/ServicesCard";
import Testimonial from "../components/testimonial/Testimonial";
import ContactUs from "../components/ContactUs";

const Home = () => {

  return (
    <div className="homepage">
      <HeroPage />

      <main id="main-content">
        <div >

          <div className="text_col">
            <h2 >
              Why we are your Trusted IT Consultant.
            </h2>
            <p>
              We are an Agency that is very involved in the growth of our clients' businesses, and thus, we give our all to see that those ideas become reality.
            </p>

          </div>
          <section>
            <QualityCards />
          </section>
        </div>
      </main>
      {/*  <!-- End main content --> */}
      <div className="middle_section">
        <h2> WHO WE ARE</h2>
        <div className="container_x flex_wrapper flex_end">
          <div className="text_col" data-aos="fade-up">
            <p>
              Welcome to Pent-Agency where ideas become Reality for less. We are dedicated to working with small and medium-sized enterprises (SMEs) providing cutting-edge services. From compelling websites and hosting solutions to implementing intelligent chatbots, managing social media pages, optimizing Google business profiles, and delivering top-notch video editing and business branding services, we are your one-stop solution for digital success. Our secret not so secret mission is to fuel the growth of fast-evolving businesses in 2024 and beyond.
            </p>
            <Link to="about" className="btn"> Learn More </Link>
          </div>
          <div className="img_col">
            <div className="img-wrap">
              {/* BG-IMG */}
            </div>
          </div>
        </div>
      </div>
      <section className="vision-mission-wrapper flex_wrapper  flex_apart">
        <div className="section-img" data-aos="fade-up">
          <img src="./images/home/full_3d_ball.svg" alt="3d_image" />
        </div>
        <div className="vision-mission-text">
          <div className="top">
            <h3>Vision Statement </h3>
            <p>"To be the premier destination for exceptional digital solutions, delivering excellence and innovation with our clients' goals in mind."</p>
          </div>
          <div className="bottom">
            <h3>Mission Statement </h3>
            <p>"Our mission is to exceed client expectations by delivering exceptional digital solutions, driven by a relentless pursuit of excellence and innovation in every project."</p>
          </div>
        </div>

      </section>
      <main id="services-section">
        <div className="text_col">
          <h2 >
            Our Mastercraft
          </h2>
          <p>
            We turn business ideas into realities for our clients thus helping them grow and stay profitable throughout their business cycle providing excellence, quality, and exceptional customer relationships; simply put, we do "More for Less."
          </p>
        </div>
        <ServicesCard />
        <Testimonial />
      </main>
      <ContactUs />

    </div>
  );
};

export default Home;
