import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect, useRef, useState } from "react";
import ProcessCard from "./components/processCard";
import BuildComponent from "./components/buildSomething";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import serviceProcess from "./serviceProcess";
import ContactUs from "../../components/ContactUs";

const Services = React.memo(() => {
  let content = useRef();
  let process = useRef();
  let [hasLoaded, setHasLoaded] = useState(false);
  const { service } = useParams();

  const pentServices = {
    chat_bots: {
      body: "Tired of clunky customer interactions? We build next-level chatbots that revolutionize the way you connect. Imagine: 24/7 engagement, personalized conversations, and effortless problem-solving, all powered by AI. \nAt Pent Agency, we don't just create chatbots – we innovate customer experiences. Let us help you streamline interactions, boost satisfaction, and unlock new growth possibilities.",
      service: "Chatbots",
      image: "/images/services/chatbot.jpg"
    },
    video_editing: {
      body: "Your brand deserves to stand out. We're not just video editors, we're storytellers who craft compelling narratives that drive results.\nImagine captivating content that engages your audience, boosts conversions, and fuels exponential growth. We help businesses innovate with video marketing, turning viewers into loyal customers.\nReady to unlock the power of professional video editing? Let's discuss your vision.",
      service: "Video Editing",
      image: "/images/services/video.jpg"
    },
    business_consulting: {
      body: "Your business has potential – let's unleash it. We're not just consultants, we're growth catalysts who identify hidden opportunities and craft innovative strategies for explosive success.\nImagine streamlined operations, increased efficiency, and groundbreaking solutions that propel you ahead of the competition. We help businesses innovate from the inside out, unlocking their full potential and fueling sustainable growth.\nReady to take your business to the next level? Let's chart a course for success.",
      service: "Business Consulting",
      image: "/images/services/consulting.jpg"
    },
    business_branding: {
      body: "Your brand is more than a logo. It's your story, your voice, your competitive edge. We're not just branders, we're architects who build distinct identities that resonate deeply.\nImagine a brand that stands out in a crowded marketplace, attracts your ideal customer, and fuels explosive growth. We help businesses innovate their brand and unlock their full potential.\nReady to transform your brand and ignite your success? Let's create something remarkable.",
      service: "Business Branding",
      image: "/images/services/business-branding.jpg"
    },
    social_media_mgt: {
      body: "Drowning in social media noise? We're the lifeguard you need. We go beyond posting - we craft engaging content strategies that ignite conversations and build thriving online communities.\nImagine a social presence that attracts new customers, boosts brand awareness, and fuels explosive growth. We help businesses innovate their social media approach and unlock the power of connection.\nReady to dominate your social media game? Let's chat about your goals.",
      service: "Social Media Management",
      image: "/images/services/social-media.jpg"
    },
    website_dev_and_hosting: {
      body: "Your website is your digital storefront - is it ready to win? We're not just website builders, we're growth partners who craft cutting-edge, user-friendly websites that fuel success.\nImagine a website that converts visitors into customers, amplifies your brand, and propels your business forward. We help businesses innovate their online presence with flawless website development and secure, reliable hosting.\nReady to unlock the power of your website? Let's build something remarkable.",
      service: "Website Development and Hosting",
      image: "/images/services/webdevhosting.jpg"
    },
    google_business_profile_optimization: {
      body: "Struggling to be seen in local searches? We're your launchpad to success. We're not just optimizers, we're growth architects who unlock the full potential of your Google Business Profile.\nImagine dominating local search results, attracting floods of new customers, and skyrocketing your online visibility. We help businesses innovate their local SEO strategy and unlock explosive growth.\nReady to take control of your Google presence? Let's put you on the map.",
      service: "Google Business Profile Optimization",
      image: "/images/services/google-biz.jpg"
    },
  };

  let filter = Object.keys(pentServices).filter((e) => e.includes(service));
  console.log(filter)
  let renderContent =
    filter.length > 0 ? pentServices[filter[0]] : "Website Development";

  useEffect(() => {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      gsap
        .timeline()
        .from("h1", {
          y: 500,
          opacity: 0,
          duration: 1,
        })
        .from(
          ".content",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.2"
        ).from(
          ".read",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.1"
        );
    }, content);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: process.current,
          start: "-30% 90%",
          end: "top center",
          // markers: true
        },
      })
      .fromTo(
        process.current,
        { y: 200 },
        {
          y: 0,
          duration: 1.4,
          ease: "power1.inOut",
        }
      );

    return () => ctx.revert(); // cleanup
  }, [service]);

  const formattedBody = renderContent.body.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      <br />
      <br />
    </React.Fragment>
  ));

  function load() {
    setHasLoaded(true);
  }

  let processContent = serviceProcess.map((elem) => (
    <ProcessCard
      key={elem.num}
      num={elem.num}
      header={elem.header}
      body={elem.body}
    />
  ));

  return (
    <main className="w-full font-noto bg-bg-color">
      <section
        ref={content}
        className="flex flex-col md:flex-row min-h-[100svh] md:min-h-[50vh] items-center gap-20 md:gap-10 mt-20 md:mt-0 responsivePadding"
      >
        <div className="textGrp">
          <p className="text-[#D4D4D4] font-normal">OUR SERVICES</p>
          <div className="overflow-hidden">
            <h1 className="text-white text-[clamp(1.5em,6vw,5em)] leading-tight mt-8 mb-2 md:my-0 lg:text-[clamp(2.5em,6vw,6em)]">
              {renderContent.service}
            </h1>
          </div>
          <div className="overflow-hidden">
            <input type="checkbox" id="read-more" className="hidden peer/readMore" />
            <p className="content font-medium text-[#D4D4D4] lg:max-w-[80%] text-[clamp(0.8em,2vw,1.1em)] mt-5 peer-checked/readMore:line-clamp-none line-clamp-4">
              {formattedBody}
            </p>
            <label for="read-more" className="cursor-pointer read peer-checked/readMore:after:content-['less'] after:content-['more'] text-stone-300 text-sm font-normal mt-1">Read </label>
          </div>
          {/* <div className="btn-holder mt-10 flex">
            <Link
              smooth={true}
              duration={500}
              to="/contact"
              className="btn text-center font-bold py-2 w-full md:max-w-[12em] rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
            >
              Get Started
            </Link>
          </div> */}
        </div>
        {hasLoaded ? null : <div className="w-[45%]"></div>}
        <img
          src={`${renderContent?.image ?? "/images/service_hero.jpg"}`}
          alt="services offered"
          className="w-full rounded_border md:w-2/5 md:h-2/5 max-h-[30rem] object-cover lg:min-w-[30rem]"
          onLoad={load}
        />
      </section>

      <section
        ref={process}
        id="services-section"
        className="process min-h-[100svh] md:min-h-screen lg:mt-20"
      >
        <h1 className="text-white text-[clamp(2em,6vw,3.5em)] text-center mt-8 mb-2 md:my-0">
          Our Process
        </h1>
        <p className="content font-medium text-[#D4D4D4]  text-center text-[clamp(0.8em,2vw,1.1em)]">
          At PENT, we leave you with a lasting impression
        </p>
        <div className="flex justify-between mt-24 gap-5 flex-col md:flex-row">
          {processContent}
        </div>
        <BuildComponent />
      </section>

      <ContactUs />
    </main>
  );
});
export default Services;
