import React from "react";
import "../HeaderFooter.css";

const ContactList = ({ icon, details, url }) => (
  <div className="linkButtonFooter footerLink font-['Noto Sans']">
    <span>{icon}</span>
    {url ? (
      <a href={url} className="font-['Noto Sans']" target="_blank" rel="noopener noreferrer">
         {details}
      </a>
    ) : (
      <small> { details } </small>
    )}
  </div>
);

export default ContactList;
