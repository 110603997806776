import './CoreValues.css'

function  ReusableValues2 ({image, h5Text, text, gap, dynamicWidth}){

  return(
    <div className={`text-center font-noto lg:p-3 pt-10 lg:inline-block relative flex flex-row ${gap} lg:items-center`}>
        <div className={`mx-auto lg:w-[3.5em] w-[9.5em] h-[5.5em] ${image? "":"blur-class"} z-50 relative `}>
            {image ? <img src={image} alt="about us" style={{width: dynamicWidth,
           }}/> : <div className='blur'></div>}
        </div>

        <div className='lg:text-center text-left'>
        <h2 className="text-lg mt-6 mb-2 z-50 text-[24px] relative">{h5Text}</h2> 
        <p className="font-normal text-[18px] text-[#A3A3A3] z-50 relative">{text}</p>
        </div>
           
    </div>
  )
}

export default ReusableValues2