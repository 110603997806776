import React, { useEffect, useRef } from "react";
import "../../App.css";
import PortFolioCard from "./components/portfolioCard";
import PortfolioCta from "./components/portfolioCta";
// import BrandComponent from "../../components/brandComponent";
import BrandCarousel from "../../components/brandCarousel/BrandCarousel";

const Portfolio = () => {
  let ref = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    setTimeout(() => {
      console.log(ref.current)
      ref.current.classList.add("showText");
    },200);
  });
  return (
    // bg-gradient-to-t from-[transparent] via-[transparent] to-primary-color-200
    <main className="min-h-screen px-[clamp(1em,8vw,5em)] w-full min-w-screen h-fit text-white font-sans major-background flex flex-col justify-between">
      {/* <h1 className='relative w-full text-center md:text-left lg:max-w-[55%] mt-14 text-white text-[clamp(2.5em,10vw,7em)] font-bold font-noto leading-none'>Where <span className='text-primary-color'>Clients</span> meet <span ref={ref} className='text-secondary-color h-fit hideText' >Satisfaction</span> </h1> */}
      <div className="header-text md:max-w-[75%] lg:max-w-[65%] text-[clamp(3.2em,8vw,6em)] lg:text-[clamp(2.5em,8vw,7em)] text-white font-bold font-noto leading-none">
        <h1 className="w-full pt-40 text-left mt-14">Where Clients meet</h1>
        <div className="overflow-hidden">
          <h1 ref={ref} className="hideText" >Satisfaction</h1>
        </div>
      </div>
      <section className="w-full h-fit grid gap-y-3  mt-12 md:mt-24 mb-20">
        <div className="flex-col md:flex-row flex gap-3">
          <PortFolioCard className="w-full md:w-3/5" imgSrc="./images/brand_carousel/scent_art_print.svg" cardCaption='SCENT ART' />
          <PortFolioCard className="w-full md:w-2/5" imgSrc="./images/brand_carousel/Ovisione.svg" cardCaption='OVISIONE' />
        </div>
        <div className="flex-col md:flex-row flex gap-3">
          <PortFolioCard className="w-full" imgSrc="./images/brand_carousel/goldland.svg" cardCaption='GOLDLAND INTERIORS FURNITURE' />
          <PortFolioCard
            className="w-full md:w-2/5"
            imgSrc="./images/brand_carousel/Uche_nnaji_redesign.svg" cardCaption='UNC'
          />
        </div>
        <div className="flex-col md:flex-row flex gap-3 ">
        {/* <PortFolioCard
            className="w-full md:w-2/5"
            imgSrc="./images/portfolio/unc.png" cardCaption='UNC'
          /> */}
          <PortFolioCard className="w-full md:w-3/5" imgSrc="./images/brand_carousel/SD-Onaiwi.svg" cardCaption='S.D ONAIWI' />
          <PortFolioCard
            className="w-full"
            imgSrc="./images/brand_carousel/paro_foundation.svg" cardCaption='PARO FOUNDATION'
          />
        </div>
        <div className="flex-col md:flex-row flex gap-3">
          <PortFolioCard className="w-full" imgSrc="./images/brand_carousel/careers verified.svg" cardCaption='CAREERS VERIFIED' />
          <PortFolioCard
            className="w-full md:w-2/5"
            imgSrc="./images/brand_carousel/pentagency.svg" cardCaption='PENTAGENCY'
          />
        </div>
      </section>
      {/* <BrandComponent className="mb-24" /> */}
      <BrandCarousel />
      <div className="mt-8"></div>
      <PortfolioCta />
    </main>
  );
};

export default Portfolio;
