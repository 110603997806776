import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileNavStateContext from "../../context/navContext";
import LinkList from "./components/LinkedList";

const TabletMobileNav = () => {
  const { setNavVisibility } = useContext(MobileNavStateContext);
  const location = useLocation();
  const selectedStyle = {
    color: "#4169E1",
    paddingBottom: "4px",
    paddingTop: "4px",
    fontWeight: "700",
    fontSize: "14px",
    fontFamily: "sans-serif",
  };

  return (
    <div className="lg:hidden fixed w-full h-full z-40">
      <div
        className=" fixed w-full h-full z-40 bg-[rgba(0,0,0,0.8)]"
        onClick={() => setNavVisibility(false)}
      ></div>
      <nav className="right-0 fixed ml-auto h-full overflow-y-auto w-4/6 md:w-1/2 bg-[#0F0F0F] max-h-screen top-0 rounded-l-xl px-5 py-5 z-50">
        <div className="flex justify-between items-center">
          <Link to="/">
            <div
              className="group"
            >
              <img
                src={
                  "/images/logo/logo_4k_x_2k_orange.png"
                }
                alt={
                  " Logo - Orange"
                }
                className="h-8 w-auto cursor-pointer"
              />
            </div>
          </Link>
          <div
            className="cursor-pointer p-2"
            onClick={() => setNavVisibility(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                d="m7 7l10 10M7 17L17 7"
              />
            </svg>
          </div>
        </div>
        <div className="menu flex flex-col gap-4 mt-10">
          <Link
            to="/"
            className={`text-white text-xl mr-2 linkButton ${location.pathname === "/"
              ? "selected linkButton selected-responsive-nav"
              : ""
              }`}
            onClick={() => setNavVisibility(false)}
            style={location.pathname === "/" ? selectedStyle : {}}
          >
            Home
          </Link>

          {/* Services Dropdown */}
          <div className="relative item">
            <input
              type="checkbox"
              className="drop-handler hidden"
              id="services"
            />
            <label for="services" className="flex items-center justify-between">
              <h3
                className={`text-white focus:outline-none flex items-center linkButton ${location.pathname.startsWith("/service") ? "selected " : ""
                  }`}
              >
                <Link to={'/services'}>Services </Link>
              </h3>
              <svg
                className="ml-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="m13.171 12l-4.95-4.95l1.415-1.413L16 12l-6.364 6.364l-1.414-1.415l4.95-4.95Z"
                />
              </svg>
            </label>
            <div className="sub-menu ml-4 overflow-hidden">
              <div className="mt-4"></div>
              <LinkList
                dismissNav={() => setNavVisibility(false)}
                items={[
                  "Business Branding",
                  "Business Consulting",
                  "Chatbots",
                  "Google Business Profile Optimization",
                  "Social Media Management",
                  "Video Editing",
                  "Website Development and Hosting",
                ]}
                urls={[
                  "/services/business_branding",
                  "/services/business_consulting",
                  "/services/chat_bots",
                  "/services/google_business_profile_optimization",
                  "/services/social_media_mgt",
                  "/services/video_editing",
                  "/services/website_dev_and_hosting",
                ]}
                baseClass="linkButton"
                selectedStyle={selectedStyle}
              />
            </div>
          </div>

          {/* About Us */}
          <Link
            to="/about"
            className={`text-white text-xl mr-2 linkButton ${location.pathname === "/about"
              ? "selected linkButton selected-responsive-nav"
              : ""
              }`}
            onClick={() => setNavVisibility(false)}
            style={location.pathname === "/about" ? selectedStyle : {}}
          >
            About Us
          </Link>

          {/* Blog */}
          <Link
            to="/blogs"
            className={`text-white text-xl mr-2 linkButton ${location.pathname === "/blogs"
              ? "selected linkButton selected-responsive-nav"
              : ""
              }`}
            onClick={() => setNavVisibility(false)}
            style={location.pathname === "/blogs" ? selectedStyle : {}}
          >
            Blog
          </Link>

        </div>
        <Link to="/contact" className="">
          <Link
            onClick={() => setNavVisibility(false)}
            to="/contact"
            className="btn_lg" style={{ width: "100%" }}
          >
            Contact Us
          </Link>
        </Link>
      </nav>
    </div>
  );
};
export default TabletMobileNav;
