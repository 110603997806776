import { useEffect } from "react";

const ErrorPage = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(<div className="min-h-[100svh] sm:min-h-[100vh] w-full flex flex-col justify-center items-center">
        <img src="/construction.png" alt="construction" className="w-[80%] md:1/2 lg:w-2/5 h-[20rem] opacity-60 object-cover"/>
        <h1 className="text-[clamp(1.3em,8vw,4em)] font-noto text-center opacity-80">Oops, Page Error</h1>
        <p className="font-noto text-md font-normal text-center"> The page you requested is does not exist</p>
    </div>)
}
export default ErrorPage;