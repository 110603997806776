import React from "react";

const PortFolioCard=({ className, imgSrc, cardCaption })=>{
    return <div className={`rounded-xl grid place-items-center bg-stone-900 w-full group h-80 relative box-border overflow-hidden border-2 border-[grey]`}>
        <div className="bg-gradient-to-b  from-[transparent] to-black absolute z-40 left-0 top-0 w-full h-full">
        </div>
        <img src={imgSrc} alt="" className="h-[8rem] group-hover:scale-125 duration-300" />
        <div className="absolute w-11/12 left-1/2 centerX p-2 rounded-lg bg-[#202020] bottom-3 z-50">
            <p className="font-bold ">{cardCaption}</p>
        </div>
    </div>
}   
export default PortFolioCard;