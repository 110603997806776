import React from "react";
import "./HeaderFooter.css";
import { Link } from "react-router-dom";
import LinkList from "./components/LinkedList";
import ContactList from "./components/ContactList";
import SocialList from "./components/SocialList";
import socialIcons from "./components/SocialIcons";

const NewFooter = () => {
  return (
    <footer>
      <div className="footer-grid-col">
        {/* first column */}
        <div className="footer-col-1">
          <Link to="/">
            <div className="footer-logo">
              <img
                src={"/images/logo/logo_4k_x_2k_orange.png"}
                alt={" Logo - Orange"}
              />
            </div>
          </Link>

          <small className="hideOnDesk">
            {" "}
            © 2024 Pent Agency.
            <br /> All right reserved.
          </small>
        </div>

        {/* second column----Services */}

        <div className="link-section footer-col-2 footer-col">
          <h5> SERVICES </h5>
          <div className="">
            {/* Your service links */}
            <LinkList
              items={[
                "Business Branding",
                "Business Consulting",
                "Chatbots",
                "Google Business Profile Optimization",
                "Social Media Management",
                "Video Editing",
                "Website Development and Hosting",
              ]}
              urls={[
                "/services/business_branding",
                "/services/business_consulting",
                "/services/chat_bots",
                "/services/google_business_profile_optimization",
                "/services/social_media_mgt",
                "/services/video_editing",
                "/services/website_dev_and_hosting",
              ]}
            />
          </div>
        </div>

        {/* THIRD COLUMN */}
        <div className=" footer-col-3 footer-col">
          <Link to="/about">ABOUT US</Link>

          <Link to="/blogs">BLOGS</Link>
        </div>

        {/* FOURTH COLUMN----Contact */}
        <div className="footer-col-4 footer-col">
          <h5>CONTACT US</h5>
          <div className="footer-contact-info">
            <ContactList
              icon={
                <img
                  src="/images/footer/call.svg"
                  alt="Icon"
                  className="icon_size"
                />
              }
              details="+1-423-833-3775"
              url="tel:+1-423-833-3775"
            />
            {/* email */}
            <ContactList
              icon={
                <img
                  src="/images/footer/email.svg"
                  alt="Icon"
                  className="icon_size email_size"
                />
              }
              details="info@pentagency.com"
              url={"mailto:info@pentagency.com"}
            />
            <ContactList
              icon={
                <img
                  src="/images/footer/location.svg"
                  alt="Icon"
                  className="location_size icon_size"
                />
              }
              details="Campus Square, 817 W Walnut St #9, Johnson City, TN 37601."
              url="https://maps.app.goo.gl/nKwEnjoo1BgJ5P8s5"
            />
          </div>
        </div>

        {/* Fifth column---Social */}
        <div className="footer-col-5 footer-col">
          <h5 className="">Social</h5>

          <div>
            {/* Social links */}
            <SocialList
              icons={socialIcons}
              descriptions={["Instagram", "LinkedIn", "X", "Facebook"]}
            />

          </div>
        </div>
      </div>

      <div className="footer-bottom">
          <small>
            © 2024 Pent Agency. | All Rights Reserved
          </small>
      </div>
    </footer>
  );
};

export default NewFooter;
