import React from 'react'
import "./Testimonial.css"


const Testimonial = () => {

    const data =[
        {
            imgUrl: "./images/testimonial/cristina.png",
            client: "Cristina Cabrera, USA",
            review: "It was amazing how my business got more visibility and as a result increased patronage just after Pent Agency began to edit my social media videos for my business. They are good at what they do. Keep up the good work guys."
        },
        {
            imgUrl: "./images/testimonial/MD-env.png",
            client: "MD environmental services, USA",
            review: "Pent Agency has excellent customer service. Their attention to detail and swift delivery are top-notch, and they will always be our preferred option."
        },
        {
            imgUrl: "./images/testimonial/Reccur.png",
            client: "Reccur, USA.",
            review: "When it comes to service delivery, Pent Agency is one of the best in the business, not only did they deliver superb service for Reccur, their team of account managers made sure we were in the loop the entire process with biweekly updates and check-ins, awesome guys."
        },
    ]
  return (
    <div className='testimonialCard-container'>
        <div className="title-subtitle-card">
            <h5>BUILDING TRUST</h5>
            <h3>Trust Earned and Preserved</h3>
        </div>
        {data.map((item, index)=>{
                const{imgUrl, client, review}=item;
            return(
              
                <div className='testimonial-card' key={index}>
                    <div className="client-wrapper">
                        <img src={imgUrl} alt={client} />
                        <h4>{client} </h4>
                    </div>
                    <p>{review}</p>
                </div>
                
            )
        
        })}

    </div>
  )
}

export default Testimonial