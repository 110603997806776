 const data = [
    {
        head: "Customer-Centric Approach",
        body: "At Pent Agency, we revolve around you. Expect nothing less than a personalized experience tailored to perfection.",
        ellipseImg: "./images/ellipse/first-ellipse.svg",
        lineImg: "./images/ellipse/first-line.svg",
        delay: 100
    },
    {
        head: "Collaborative Partnership",
        body: "Thrive in synergy. Our collaborative partnerships with tech leaders pave the way for your business's transformational journey.",
        ellipseImg: "./images/ellipse/second-ellipse.svg",
        lineImg: "./images/ellipse/second-line.svg",
        delay: 200
    },
    {
        head: "Innovation at the Core",
        body: "Experience the power of innovation firsthand as we pioneer new paths for your business's growth and success.",
        ellipseImg: "./images/ellipse/third-ellipse.svg",
        lineImg: "",
        delay: 300

    },
    {
        head: "Strategic Consulting For Growth",
        body: "Embrace strategic brilliance with our consulting expertise, orchestrating your business's growth story with precision and foresight.",
        ellipseImg: "./images/ellipse/fourth-ellipse.svg",
        lineImg: "./images/ellipse/fourth-line.svg",
        delay: 100
    },
    {
        head: "More For Less",
        body: "Unlock premium solutions at unbeatable prices. Our 'More for Less' promise delivers tailored excellence without breaking the bank.",
        ellipseImg: "./images/ellipse/fifth-ellipse.svg",
        lineImg: "./images/ellipse/fifth-line.svg",
        delay: 200
    },
    {
        head: "Industry-Relevant Solutions",
        body: "Navigate industry complexities with confidence. Our solutions are finely tuned to your sector's nuances, ensuring your success in a competitive landscape.",
        ellipseImg: "./images/ellipse/sixth-ellipse.svg",
        lineImg: "",
        delay: 300
    },

]

export default data;