import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "./pagination/Pagination";


const DesktopRecentBlogs = ({blogContent}) => {
    // USESTATE AND VARIABLES FOR PAGINATION
  const[currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(3);

  // GET CURRENT PROJECT
  const indexOfLastPost = currentPage * postPerPage
  const indexOfFirstPost = indexOfLastPost - postPerPage 

  const currentPost = blogContent.slice(indexOfFirstPost, indexOfLastPost)
  // replace filterStack with currentPage in the mapping block

  return (
  <>
    <div className="desktop_blog_wrapper">
      {currentPost.map((blog, id) => {
        const { title, image, name, content} = blog;
        return (
          <div className="blog_card" key={id}>
            <div className="blog_image">
              <img src={image} alt={name} />
            </div>
            <h2>{title} </h2>
            {content.map((post, index)=>{
              return <p key={index}>{post.introduction.slice(0,199)}...</p>
            })}
            <Link to={`/blog/${name}`}>Read more</Link>
          </div>
        );
      })}
    </div>
        <Pagination 
        currentPage={currentPage}
        setCurrentPage={setCurrentPage} 
        postPerPage={postPerPage}
        totalPost={blogContent.length} 
      />
    </>
  );
  // return (
  //   <div className="desktop_blog_wrapper">
  //     {blogContent.map((blog, id) => {
  //       const { title, image, name, content} = blog;
  //       return (
  //         <div className="blog_card" key={id}>
  //           <div className="blog_image">
  //             <img src={image} alt={name} />
  //           </div>
  //           <h2>{title} </h2>
  //           {content.map((post, index)=>{
  //             return <p key={index}>{post.introduction.slice(0,199)}...</p>
  //           })}
  //           <Link to={`/blog/${name}`}>Read more</Link>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

export default DesktopRecentBlogs;
