import React from 'react';
import { Link } from 'react-router-dom';

const CustomModal = ({ isOpen, onClose }) => {
  return (
    <div className={`custom-modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-content">
      <button className="close-button" onClick={onClose}>
        <Link to='/'>x</Link>
        </button>
        <h1 class="lg:text-5xl mb-10">Thank you for contacting us.</h1>
        <h1 class="lg:text-5xl">We will be in touch shortly.</h1>
      </div>
    </div>
  );
};

export default CustomModal;
