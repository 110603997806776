import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProcessCard from "./components/processCard";
import serviceProcess from "./serviceProcess";
import ContactUs from "../../components/ContactUs";
import BuildComponent from "./components/buildSomething";
export const MainServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let processContent = serviceProcess.map((elem) => (
    <ProcessCard
      key={elem.num}
      num={elem.num}
      header={elem.header}
      body={elem.body}
    />
  ));
  return (
    <main className="min-h-screen w-full bg-black font-noto mb-6">
      <header className="w-full bg-black pt-24 mb-20 flex items-center">
        <div className="w-full relative min-h-[40vh] md:min-h-[80vh] h-[30vh] data-te-lazy-load-init data-te-lazy-src bg-cover bg-center bg-[url(/public/images/services/test.png)] flex justify-center items-center flex-col px-[clamp(1em,8vw,5em)]">
          <h1 className="text-[clamp(2.7em,6vw,4.5em)] max-w-[95%] md:max-w-[70%] lg:max-w-[55%] text-center">
            Our Services
          </h1>
          <p className=" font-normal px-10 text-[clamp(0.9em,2vw,1.25em)] text-center">
            We are your reliable partner, we take your business to its peak
          </p>
        </div>
      </header>
      <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] px-[clamp(1em,8vw,5em)] text-center">
        Our Core Proficiency
      </h1>
      <p className="content font-normal text-[#D4D4D4] px-[clamp(1em,8vw,5em)] text-center text-[clamp(0.7em,3vw,1em)]">
        experience unrivaled quality
      </p>
      <section className="grid grid-col-1 place-content-center md:grid-cols-2 px-[clamp(1em,8vw,5em)] mt-16 gap-12 lg:gap-20 pb-20">

        {/* business branding */}
        <div className="flex justify-center items-center row-start-1 md:row-start-auto">
          <img
            src="/images/services/business-branding.jpg"
            alt="Business branding" loading="lazy"
            className="h-full lg:h-[22em] rounded_border object-cover w-full"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-2 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Business Branding</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Your brand is more than a logo. It's your story, your voice, your competitive edge. We're not just branders, we're architects who build distinct identities that resonate deeply.
              Imagine a brand that stands out in a crowded marketplace, attracts your ideal customer, and fuels explosive growth. We help businesses innovate their brand and unlock their full potential.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/business_branding"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        {/* business Consulting */}
        <div className="w-full flex flex-col gap-8 row-start-4 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Business Consulting</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Your business has potential – let's unleash it. We're not just consultants, we're growth catalysts who identify hidden opportunities and craft innovative strategies for explosive success.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/business_consulting"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="flex justify-center items-center row-start-3 md:row-start-auto">
          <img src="/images/services/consulting.jpg" alt="Business Consulting" loading="lazy" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>

        {/* chatbots */}
        <div className="flex justify-center items-center row-start-5 md:row-start-auto overflow-hidden">
          <img
            src="/images/services/chatbot.jpg"
            alt="chatbots"
            className="h-full rounded_border lg:h-[22em] object-cover w-full" loading="lazy"
          />
        </div>

        <div className="w-full flex flex-col gap-8 row-start-6 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Chatbots</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Tired of clunky customer interactions? We build next-level chatbots that revolutionize the way you connect. Imagine: 24/7 engagement, personalized conversations, and effortless problem-solving, all powered by AI.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/chat_bots"
            className="btn w-1/2 text-center font-bold py-4 focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        {/* google biz */}
        <div className="w-full flex flex-col gap-8 row-start-8 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Google Business Profile Optimization</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Struggling to be seen in local searches? We're your launchpad to success. We're not just optimizers, we're growth architects who unlock the full potential of your Google Business Profile.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/google_business_profile_optimization"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        <div className="flex justify-center items-center row-start-7 md:row-start-auto">
          <img src="/images/services/google-biz.jpg" loading="lazy" alt="Google Biz" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>

        {/* social mdeia mgt */}
        <div className="flex justify-center items-center row-start-9 md:row-start-auto">
          <img
            src="/images/services/social-media.jpg"
            alt="social mdeia mgt" loading="lazy"
            className="h-full lg:h-[22em] rounded_border object-cover w-full"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-10 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Social Media Management</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Drowning in social media noise? We're the lifeguard you need. We go beyond posting - we craft engaging content strategies that ignite conversations and build thriving online communities.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/social_media_mgt"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        {/* vidoe editing */}
        <div className="w-full flex flex-col gap-8 row-start-12 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Video Editing</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Your brand deserves to stand out. We're not just video editors, we're storytellers who craft compelling narratives that drive results.
              <br />
              <br />
              Imagine captivating content that engages your audience, boosts conversions, and fuels exponential growth. We help businesses innovate with video marketing, turning viewers into loyal customers.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/video_editing"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="flex justify-center items-center row-start-11 md:row-start-auto">
          <img src="/images/services/video.jpg" loading="lazy" alt="video editing" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>

        {/* website dev */}
        <div className="flex justify-center items-center row-start-13 md:row-start-auto">
          <img
            src="/images/services/webdevhosting.jpg" loading="lazy"
            alt="website dev"
            className="h-full lg:h-[22em] object-cover rounded_border w-full"
          />
        </div>

        <div className="w-full flex flex-col gap-8 row-start-14 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Website Development and Hosting</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Your website is your digital storefront - is it ready to win? We're not just website builders, we're growth partners who craft cutting-edge, user-friendly websites that fuel success.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/website_dev_and_hosting"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

      </section>
      <section
        // ref={process}
        id="services-section"
        className="process min-h-[70svh] md:min-h-[70vh] mt-20 "
      >
        <h1 className="text-white text-[clamp(2em,6vw,3.5em)] text-center mt-8 mb-2 md:my-0">
          Our Process
        </h1>
        <p className="content font-medium text-[#D4D4D4]  text-center text-[clamp(0.8em,2vw,1.1em)]">
          At Pent, we leave you with a lasting impression
        </p>
        <div className="flex justify-between mt-24 gap-5 flex-col md:flex-row process-wrapper">
          {processContent}
        </div>
        <BuildComponent />
      </section>
      <ContactUs className='px-[clamp(1em,8vw,5em)]' />
    </main>
  );
};

export default MainServices;
