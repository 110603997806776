const data = [
    {
        icon: "./images/home/icons/biz-brand.svg",
        service: "Business branding",
        text: "Transforming businesses into unforgettable brands. Our expertise in branding sparks innovation, redefining identities and driving success."
    },
    {
        icon: "./images/home/icons/biz-con.svg",
        service: "Business consulting",
        text: "Reach new milestones with our strategic guidance. We equip businesses with the tools and insights needed to achieve their goals and surpass expectations."
    },
    {
        icon: "./images/home/icons/chatbot.svg",
        service: "Chatbots",
        text: "Revolutionize customer engagement with our expertly crafted Chatbots. Transforming businesses through innovative conversational experiences."
    },
    {
        icon: "./images/home/icons/google-biz.svg",
        service: "Google business profile optimization",
        text: "Crafting Google profiles that stand out. Our optimization expertise innovates businesses, boosting visibility and attracting more customers with strategic profiling."
    },
    {
        icon: "./images/home/icons/social-media.svg",
        service: "Social media management",
        text: "Empower your brand's voice. Our expert social management innovates businesses, driving engagement, and fostering meaningful connections with your audience."
    },
    {
        icon: "./images/home/icons/vid-ed.svg",
        service: "Video editing",
        text: "Experience the art of storytelling with our video editing prowess. Redefining business narratives, one frame at a time."
    },
    {
        icon: "./images/home/icons/web-dev.svg",
        service: "Website development and Hosting",
        text: "Redefine your online identity with our web solutions. We propel businesses towards digital success with unmatched reliability."
    },

]

export default data;