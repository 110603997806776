import gsap, { Expo } from "gsap";
import { useEffect, useRef } from "react";
import ContactCard from "./components/contactCard";
import ContactUs from "../../components/ContactUs";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const imageRef = useRef();
  const contactInfo = [
    {
      "icon": "/images/contact_us/location.svg",
      "title": "Visit Us",
      subtitle: "Find us at",
      body: "Campus Square, 817 W Walnut St #9, Johnson City, TN 37601."
    },
    {
      "icon": "/images/contact_us/call.svg",
      "title": "Call Us",
      subtitle: "Round the clock support",
      body: "+1-423-833-3775"
    },
    {
      "icon": "/images/contact_us/message-2.svg",
      "title": "Contact Us",
      subtitle: "Feel free to shoot us a mail",
      body: "info@pentagency.com"
    }
  ]

  function onload(event) {
    gsap.from(imageRef.current, {
      height: 0,
      duration: 1.3,
      ease: Expo.easeInOut,
    });
  }

  return (
    <main
      className="" >
      <header className="lg:h-screen w-full flex items-start relative bg-no-repeat bg-contain bg-center min-h-[40vh] md:min-h-[80vh] h-[30vh] bg-[url(/public/images/contact_us/thumbnail_contactHero.jpg)]">
        <div className="w-full h-full flex justify-center items-center relative">
          <img
            onLoad={onload}
            ref={imageRef}
            src="/images/hero_bg.png"
            alt="contact headerImage"
            className="absolute centerXY w-full object-cover h-[75vh] mt-[2vh]"
          />
          <div className="text-center z-30 mx-[clamp(1em,8vw,5em)]">
            <h1 className="text-[clamp(2.7em,6vw,6.5em)]">
              Contact Us
            </h1>
          </div>
        </div>
      </header>

      <div className="flex justify-around w-full px-[clamp(1em,8vw,5em)] pt-10 flex-col gap-y-10 md:flex-row md:gap-0 items-center pb-28">
        {
          contactInfo.map((item) => <ContactCard key={item.title} icon={item.icon} title={item.title} body={item.body} subTitle={item.subtitle} />)
        }
      </div>
      <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] px-[clamp(1em,8vw,5em)] text-center">
        Pay us a Visit
      </h1>
      <p className="content font-normal text-[#D4D4D4] px-[clamp(1em,8vw,5em)] text-center text-[clamp(0.7em,3vw,1em)] mb-10">
        Get directions on Google Map
      </p>
      <iframe
        title="Pent Agency's on map location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1379.1721355734223!2d-82.36403657798488!3d36.30718367176492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885a7ce972adfcd7%3A0x7f99fb278569d0d5!2s817%20W%20Walnut%20St%20%239%2C%20Johnson%20City%2C%20TN%2037604%2C%20USA!5e0!3m2!1sen!2sng!4v1713539579990!5m2!1sen!2sng"
        className="w-full h-[30em]"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <ContactUs className="px-[clamp(1em,8vw,5em)] justify-between" textClass="md:max-w-[30em] " />
      <div className="mb-32"></div>
    </main>
  );
};
export default ContactUsPage;
