

const ProcessCard = ({ num, header, body }) => {

  return (
    <div  className="process-container">

      <div className="process">
        <span className="process-num">
          <small>{`0${num}`}</small>
        </span>
        <h2>{header}</h2>
        <p>{body}</p>
      </div>

    </div>
  );
};
export default ProcessCard;
