import { Link } from "react-router-dom";

const BuildComponent = ()=>{
    return (
        <div className="bg-[#FFFFFF1A] py-28 border-2 border-primary-color-500 rounded-sm flex flex-col items-center gap-5 mt-32 px-2">
            <h2 className="font-bold text-[clamp(1.5em,3vw,2em)] text-center">Ready? Let’s build something great</h2>
            <button className="font-bold bg-primary-color-500 py-2 w-full max-w-[12em] btn_lg">
              <Link to="/contact">Get Started</Link>
            </button>
        </div>
    );
}
export default BuildComponent;