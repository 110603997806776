import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const LinkListNew = ({ items, baseClass, selectedStyle, urls }) => {
  const location = useLocation();
  return (
    <ul className={`flex flex-col gap-2`}>
      {items.map((item, index) => (
        <li
          key={index}
          className={`text-white text-sm pb-1 font-bold ${baseClass} font-['Noto Sans'] leading-tight tracking-tight`}
          style={
            location.pathname === (urls[index])
              ? selectedStyle
              : null
          }
        >
          <Link to={urls[index]}>
            {item}
          </Link>
        </li>
      ))}
    </ul>
  );
};


const iconStyle = {
  marginLeft: "4px",
  fontSize: "12px",
  fontWeight: "normal",
};

const selectedStyle = {
  color: "#FFA000",
  borderBottom: "3px solid",
  paddingBottom: "4px",
  fontWeight: "700",
  fontSize: "14px",
};

const NavBar = () => {
  const location = useLocation();

  const servicesRef = useRef(null);
  const aboutUsRef = useRef(null);
  const moreRef = useRef(null);

  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const closeAllDropdowns = () => {
    setServicesDropdownOpen(false);
  };

  const openDropdownWithDelay = (toggleFunction) => {
    toggleFunction();
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isOutsideServices =
        !servicesRef.current || !servicesRef.current.contains(event.target);
      const isOutsideAboutUs =
        !aboutUsRef.current || !aboutUsRef.current.contains(event.target);
      const isOutsideMore =
        !moreRef.current || !moreRef.current.contains(event.target);

      if (isOutsideServices && isOutsideAboutUs && isOutsideMore) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <nav className="bg-opacity-0 p-4 hidden lg:block desk-nav">
      <div className=" mx-auto flex space-x-4 justify-center relative">
        {/* Home */}
        <Link
          to="/"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/" ? "selected linkButton" : ""
          }`}
          style={location.pathname === "/" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          Home
        </Link>

        {/* Services Dropdown */}
        <div
          className="relative font-noto"
          ref={servicesRef}
          onMouseEnter={() => openDropdownWithDelay(toggleServicesDropdown)}
          onMouseLeave={() => setServicesDropdownOpen(false)}
        >
          <button
            className={`text-white focus:outline-none flex items-center linkButton ${
              location.pathname.startsWith("/service") ? "selected " : ""
            }`}
          >
            <Link to={"services"} className={`text-white mr-2 linkButton ${
            location.pathname === "/" ? "selected linkButton" : ""
          }`}> Services</Link>
            {isServicesDropdownOpen ? (
              <FaChevronUp style={iconStyle} />
            ) : (
              <FaChevronDown
                onClick={toggleServicesDropdown}
                style={iconStyle}
              />
            )}
          </button>
          {isServicesDropdownOpen && (
            <div className="absolute pt-2">
              <div className="h-fit w-[40rem] bg-[#131313] rounded-md py-10 px-20 ml-1">
                <p className="text-[#86868B] text-sm font-normal mb-3">
                  Explore Services
                </p>
                <div className="links-holder flex w-full justify-between items-center -left-32">
                  <LinkListNew
                    baseClass="hover:text-[#FFA000] duration-75"
                    selectedStyle={selectedStyle}
                    items={[
                      "Business Branding",
                      "Business Consulting",
                      "Chatbots",
                    ]}
                    urls={[
                    "/services/business_branding",                    
                    "/services/business_consulting",
                    "/services/chat_bots",
                  ]}
                  />
                  <LinkListNew
                    baseClass="hover:text-[#FFA000] duration-75"
                    selectedStyle={selectedStyle}
                    items={[
                      "Google Business Profile Optimization",                      
                      "Social Media Management",
                      "Video Editing",
                      "Website Development and Hosting",
                    ]}
                    urls={[
                    "/services/google_business_profile_optimization",                    
                    "/services/social_media_mgt",
                    "/services/video_editing",
                    "/services/website_dev_and_hosting",
                  ]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* About Us Dropdown */}
        <Link
          to="/about"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/about" ? "selected linkButton" : ""
          }`}
          style={location.pathname === "/about" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          About Us
        </Link>

        {/* Blog*/}
        <Link
          to="/blogs"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/blogs" ? "selected linkButton" : ""
          }`}
          style={location.pathname === "/blogs" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          Blog
        </Link>
      </div>
    </nav>
  );
};


export default NavBar;
