import React from 'react'
import './ServicesCard.css'
import data from "./data"

const ServicesCard = () => {
  return (
    <div className='servicesCard-container'>
        <div className="title-card">
            <h5>What we do</h5>
            <h3>Services We Offer</h3>
        </div>
        {data.map((item, index)=>{
                const{icon, service, text}=item;
            return(
                <div className='service-card' key={index}>
                    <img src={icon} alt={service} />
                    <h4>{service} </h4>
                    <p>{text}</p>
                </div>
            )
        
        })}

    </div>
  )
}

export default ServicesCard