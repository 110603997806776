import React from "react";

const socialIcons = [
  {
    path: "https://www.instagram.com/pent_Agency/",
    icon: (
      <img
        key="icon1"
        src="/images/footer/instagram.svg"
        alt="Instagram"
        className="w-6 md:w-4 lg:w-5 h-fit"
      />
    ),
  },
  {
    path: "https://www.linkedin.com/company/pent-agency/",
    icon: (
      <img
        key="icon2"
        src="/images/footer/linkedIn.svg"
        alt="LinkedIn"
        className="w-6 lg:w-4 md:w-4 h-fit"
      />
    ),
  },
  {
    path: "",
    icon: (
      <img
        key="icon3"
        src="/images/footer/x.svg"
        alt="X"
        className="w-6 md:w-4 lg:w-4 h-fit"
      />
    ),
  },
  {
    path: "",
    icon: (
      <img
        key="icon4"
        src="/images/footer/facebook.svg"
        alt="Facebook"
        className="w-4 md:w-3 lg:w-3 h-fit"
      />
    ),
  },
];

export default socialIcons;
