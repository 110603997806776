import "../styles/AboutUs.css";
import { Link } from "react-router-dom";
import ContactUs from "../components/ContactUs";
import { useEffect, useRef } from "react";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";
import gsap from "gsap/all";
import { ScrollTrigger } from "gsap/all";

import ReusableValues from "../components/reuseableFunctions/AboutUsCards";
import ReusableValues2 from "../components/reuseableFunctions/AboutUsCards2";

const AboutUs = () => {
  let content = useRef();
  let process = useRef();
  const { training } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      gsap
        .timeline()
        .from("h1", {
          y: 500,
          opacity: 0,
          duration: 1,
        })
        .from(
          ".content",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.2"
        ).from(
          ".read",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.1"
        );
    }, content);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: process.current,
          start: "-30% 90%",
          end: "top center",
          // markers: true
        },
      })
      .fromTo(
        process.current,
        { y: 200 },
        {
          y: 0,
          duration: 1.4,
          ease: "power1.inOut",
        }
      );

    return () => ctx.revert(); // cleanup
  }, [training]);


  return (
    <div className="main-container">
      <header className="_homepage_hero grid_wrapper">

        <h1>About Us</h1>


        <p className='px-9 hero_gap' >
          Ideas to Empires: We build profitable businesses. Grow faster. Pay less.
        </p>

        <Link
          to="/services"
          smooth={true}
          duration={500}
          className="btn_lg hover:scale-105 transform transition duration-300"
        >
          <div className="button-text text-white text-sm font-bold font-['Noto Sans'] tracking-tight">Get Started</div>
        </Link>
      </header>

      {/* who we are */}
      <div className="middle_section about_middle_section">
        <h2> WHO WE ARE</h2>
        <div className="container_x flex_wrapper flex_end">
          <div className="text_col" data-aos="fade-up">
            <p>
              Welcome to Pent-Agency where ideas become Reality for le$$. We are dedicated to working with small and medium-sized enterprises (SMEs) providing cutting-edge services. From compelling websites and hosting solutions to implementing intelligent chatbots, managing social media pages, optimizing Google business profiles, and delivering top-notch video editing and business branding services, we are your one-stop solution for digital success.
              <br />
              Our secret not-so-secret mission is to fuel the growth of fast-evolving businesses in 2024 and beyond. With a knack for staying at the forefront of industry trends, we combine technical proficiency with creative flair to elevate your online presence. Whether you're a tech startup, an e-commerce pioneer, a health-tech disruptor, or a sustainable business champion, we understand the unique needs of your industry.
            </p>
          </div>
          <div className="img_col">
            <div className="img-wrap">
              {/* BG-IMG */}
            </div>
          </div>
        </div>
        <p className="last_text" data-aos="fade-up">
          At Pent-Agency, we don't just provide services; we forge friendships. Our experienced team of consultants is committed to understanding your goals and crafting customized strategies to propel your business forward regardless of economic situations. Let us be your trusted ally on this path to success, as we navigate the digital landscape together. <br /> We look forward to hearing from you.
        </p>
      </div>

      <section className="vision-mission-wrapper flex_wrapper  flex_apart">
        <div className="section-img" data-aos="fade-up">
          <img src="./images/home/full_3d_ball.svg" alt="3d_image" />
        </div>
        <div className="vision-mission-text">
          <div className="top">
            <h3>Vision Statement </h3>
            <p>"To be the premier destination for exceptional digital solutions, delivering excellence and innovation with  our clients' goals in mind."</p>
          </div>
          <div className="bottom">
            <h3>Mission Statement </h3>
            <p>"Our mission is to exceed client expectations by delivering exceptional digital solutions, driven by a  relentless pursuit of excellence and innovation in every project."</p>
          </div>
        </div>
      </section>

      <div className="core  pb-[70px] pt-10 lg:px-0 px-10">
        <h1 className="text-[60px] font-bold">Our Core Values</h1>
        <h3 className="lg:pt-0 font-normal text-[25px] text-center">Our Values shape everything we do and how we do it</h3>
      </div>

      <div className="core-value-wrapper">
        <div className="cores-values ">
          <ReusableValues
            image="./images/about_us/icons/weight.svg"
            h5Text={"Diligence"}
            text={
              "At Pent Agency, diligence sets us apart. It's the backbone of our service, guaranteeing results that inspire trust and confidence."
            }
          />
          <ReusableValues
            image="./images/about_us/icons/strongbox-2.svg"
            h5Text={"Integrity"}
            text={
              "Integrity is non-negotiable; it's the cornerstone of our commitment, ensuring honesty, accountability, and ethical business practices at every turn."
            }
          />
          <ReusableValues
            image="./images/about_us/icons/grid-eraser.svg"
            width={"w-[5.5em]"}
            height={"h-[5.5em]"}
            h5Text={"Excellence"}
            text={
              "Excellence is our trademark at Pent Agency. It's the standard we uphold, ensuring every project reflects our dedication to quality, innovation, and client satisfaction."
            }
          />
        </div>
      </div>

      <div className="business lg:px-0 px-10 grid place-items-center">
        <h1 className="font-semibold">We are Preferred</h1>
        <p>Experience unrivaled quality</p>
      </div>

      <div className="business-value-wrapper">
        <div className={"business-values flex justify-center"}>
          <ReusableValues2
            image="./images/ellipse/first-ellipse.svg"
            h5Text={"Value For Money"}
            dynamicWidth={"40px"}
            gap={"gap-6"}
            text={
              "At Pent Agency, value is our currency. Experience premium quality and results without the premium price tag, setting new standards for affordability and excellence."
            }
          />
          <ReusableValues2
            image="./images/ellipse/second-ellipse.svg"
            h5Text={"Consistency in Quality"}
            dynamicWidth={"40px"}
            gap={"gap-4"}
            text={
              "Our commitment to excellence ensures every project meets the highest standards, consistently exceeding expectations."
            }
          />
          <ReusableValues2
            image="./images/ellipse/third-ellipse.svg"
            h5Text={"100% Delivery Rate"}
            gap={"gap-4"}
            dynamicWidth={"40px"}
            text={
              "Trust Pent Agency for flawless delivery. Our commitment to 100% Delivery Rate ensures projects are completed on time, every time, without compromise."
            }
          />
        </div>
      </div>
      <ContactUs />
    </div>
  );
};

export default AboutUs;
