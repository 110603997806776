import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import Layout from './components/layout';
import Internship from './pages/internship/Internship';
import './index.css'
import AboutUs from "./pages/AboutUs";
import Lenis from '@studio-freight/lenis';
import Services from './pages/Services/services'
import MainServices from './pages/Services/MainServices'
import Blog from './pages/blog/Blog';
import Openings from './pages/Opening';
import ErrorPage from './components/ErrorPage';
import ContactUsPage from './pages/Contact/contactUsPage';
import BlogList from './pages/blog/BlogList';
import InternshipPost from './pages/internship/InternshipPost';
import initializeAOS from './components/aosInitializer';


initializeAOS();

function App() {
  
  const lenis = new Lenis()

lenis.on('scroll', (e) => {
  // console.log(e)
})

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="internship" element={<Internship />} />
          <Route path="internship/:internship" element={<InternshipPost/>} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="blogs" element={<BlogList/>}/>
          <Route path="blog/:name" element={<Blog />} />
          <Route path="contact" element={<ContactUsPage />} />
          <Route path="services/:service" element={<Services />} />
          <Route path="services" element={<MainServices />} />
          <Route path="openings" element={<Openings />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
