import React from 'react'
import data from "./data"
import "./QualityCards.css";
import "aos/dist/aos.css";


const QualityCards = () => {


  return (
    <div className="qualities-card-container">
        {data.map((item, index)=>{
            const{head, body, ellipseImg, lineImg, delay} = item
            
            return(
                <div className="quality-card" key={index}  data-aos="fade-up" data-aos-delay={delay} data-aos-offset="200">
                    <div className="timeline">
                        <img src={ellipseImg} alt="elipse" className='ellipse'/>
                        {index === 3 || 6? <img src={lineImg} className="dashed-line" alt="" /> : null }
                    </div>
                    <div className="card-content">
                        <h3>{head}</h3>
                        <p>{body} </p>
                    </div>
                </div>
            )
        
        })}
    
    </div>
  )
}

export default QualityCards