import React, { useEffect } from 'react';
import '../styles/Home.css';
import "../components/headerFooter/HeaderFooter.css";
import Typewriter from "typewriter-effect";
import Aos from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';


const HeroPage = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 })

  }, [])
  return (
    <header className="homepage_hero inner_padding ">
      <Typewriter
        className="hero_head"
        onInit={(typewriter) => {
          typewriter
            // .typeString("<h1>First-class ICT </br> Consultants.</h1>")
            .typeString("<h1>Strategic IT Partners. Transforming Businesses. Digitally.  </h1>")
            .pauseFor(2500)
            .deleteAll()
            .start();
        }}
        options={{
          autoStart: true,
          loop: true,
          delay: 50,
          deleteSpeed: 10
        }}
      />

      <p className='px-9 md:px-0 hero_gap '>
        From Strategy to Scale. Unleashing Digital Growth for your Business.
      </p>

      <Link
        to="services"
        smooth={true}
        duration={500}
        className="btn_lg hover:scale-105 transform transition duration-300 ease-in-out"
        data-aos='fade-up'
        data-aos-delay='100'
        data-aos-anchor-placement="top-bottom"
      >
        <div className="button-text text-white text-sm font-bold font-['Noto Sans'] tracking-tight">Get Started</div>
      </Link>
    </header>
  );
};

export default HeroPage;