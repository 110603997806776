import React from 'react'
import { useState } from 'react'
import './Pagination.css'



const Pagination = ({postPerPage, currentPage, setCurrentPage, totalPost}) => {

    const pageNumbers = []
    const totalPages = totalPost / postPerPage;
    // limit page number shown
    const [pageNumberLimit, setPageNumberLimit] = useState(3)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    for (let i = 1; i <= Math.ceil(totalPost / postPerPage); i++){
        pageNumbers.push(i)
    }
    console.log(totalPost )
    // paginate
    const paginate =(pageNumbers)=>{
        setCurrentPage(pageNumbers)
    }
    // go to next
    const paginateToNext =()=>{
        setCurrentPage(prev => prev + 1)
        // show next set of page number
        if(currentPage + 1 > maxPageNumberLimit){
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    }
    // go to previous
    const paginateToPrev = () =>{
        setCurrentPage(prev => prev - 1)

        // show prev set of page number 
        if((currentPage - 1) % pageNumberLimit === 0){
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    }

  return (
    
    <ul className="pagination">

        <li onClick={paginateToPrev} className={currentPage === pageNumbers[0]? "posthidden" : null} >Prev</li>

            {pageNumbers.map((number)=>{
                if (number < maxPageNumberLimit + 1 && number >minPageNumberLimit){
                    return (
                        <li key={number} onClick={()=>paginate(number)} className={currentPage === number? `active` : null} >
                            <small>{number}</small>
                        </li>
                    );
                }
            })}

        <li onClick={paginateToNext} className={currentPage === pageNumbers[pageNumbers.length - 1] ? "posthidden" : null}>Next</li>
        {/* <p>
            <b>
                {`page ${currentPage}`}
            </b>
            <span>{` of `}</span>
            <b>
                {`${Math.ceil(totalPages)}`}
            </b>            
        </p> */}
    </ul>
    
  )
}

export default Pagination