import { Link } from "react-router-dom";


const PortfolioCta = () => {
  return (
    <div className="relative flex flex-col justify-center h-80 w-full bg-gradient-to-t from-[transparent] via-[transparent] mb-24 to-primary-color-200 rounded-xl px-8">
      <h1 className="font-noto text-[clamp(1.5em,6vh,75em)] lg:text-[clamp(1.5em,8vh,7em)] z-20 font-bold leading-tight ">
        Want to collaborate?
      </h1>
      <button className="bg-primary-color-500 mt-3 md:mt-1 flex gap-2 hover:bg-[#3451a7] z-20 duration-150 rounded-md px-3 py-2 font-bold w-fit">
        <Link to="/contact">Start a Project{" "}</Link>
        <span>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4297 6.43005L20.4997 12.5001L14.4297 18.5701"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.5 12.5H20.33"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
      <img src="./images/medium-3d.svg" alt=""  className="absolute right-0 md:left-1/3 top-0 w-fit h-2/5 z-10"/>
      <img src="./images/medium-3d.svg" alt=""  className="absolute left-32 bottom-0 w-fit h-1/4 z-10"/>
      <img src="./images/large-3d.svg" alt=""  className="absolute right-0 bottom-0 w-fit h-full z-10 md:block hidden"/>
    </div>
  );
};
export default PortfolioCta;
