import './CoreValues.css'

function  ReusableValues ({image, h5Text, text}){

  return(
    <div className={' rounded-lg font-noto p-5 relative rounded-lg  border border-[#A3A3A3]'}>
        <div className={`rounded-full w-[2.5em] h-[2.5em] ${image? "":"blur-class"} z-50 relative `}>
            {image ? <img src={image} alt="about us i" style={{width: "30px"}}/> : <div className='blur'></div>}
        </div>

        
        <h2 className="text-lg mt-6 mb-2 z-50 text-[24px] relative">{h5Text}</h2> 
        <p className="font-normal text-[18px] text-[#A3A3A3] z-50 relative">{text}</p>   
    </div>
  )
}

export default ReusableValues